import React, { useEffect, useState } from 'react'
import { createWorker } from 'tesseract.js'

const ImageCapture = () => {
    const [imageData, setImageData] = useState('')
    const [ocr, setOcr] = useState(null)
    const [log, setLog] = useState({})

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                // Select First item
                const file = target.files[0]
                const newUrl = URL.createObjectURL(file)
                setOcr(null)
                setLog({})
                setImageData(newUrl)
            }
        }
    }
    useEffect(() => {
        const convertImageToText = async () => {
            if (!imageData) {
                return
            }
            const worker = await createWorker('eng', 1, {
                // Add logger here
                logger: m => {
                    //console.log('convertImageToText...', m)
                    setLog({ status: m.status, progress: m.progress })
                }
            })


            const {
                data: { text }
            } = await worker.recognize(imageData)

            setOcr(text)
            await worker.terminate()
        }

        convertImageToText()
    }, [imageData])

    const renderOCR = () => {
        if (log && log.status === 'recognizing text' && log.progress === 1 && ocr) {
            return <><h3>Extracted Text</h3> {ocr}</>
        }

        if (Object.keys(log).length > 0) {
            return <>Text extraction in progress....</>
        }
        return <></>
    }

    return (

        <div className="content">
            <div className="page-title">
                <h1>Image Capture</h1>
            </div>
            <div style={{ display: 'block', width: '100%', paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div style={{ display: 'block', width: '100%', paddingBottom: '1rem' }}>
                    <label htmlFor="selfie" style={{ fontWeight: 'bold' }}>Front Camera:  </label>
                    <input
                        accept="image/*"
                        id="selfie"
                        type="file"
                        capture="user"
                        onChange={(e) => handleCapture(e.target)}
                    />
                </div>
                <div style={{ display: 'block', width: '100%', paddingBottom: '1rem' }}>
                    <label htmlFor="picture" style={{ fontWeight: 'bold' }}>Back Camera:  </label>
                    <input
                        accept="image/*"
                        id="picture"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                    />
                </div>
                <div style={{ display: 'block', width: '100%', paddingBottom: '1rem' }}>
                    <label htmlFor="picture" style={{ fontWeight: 'bold' }}>Any Source:  </label>
                    <input
                        accept="image/*"
                        id="picture"
                        type="file"
                        onChange={(e) => handleCapture(e.target)}
                    />
                </div>
                <br />
                <div style={{ display: 'block' }}>
                    {imageData &&
                        <div>
                            <img src={imageData} alt={'snap'} width="250" height="250" />
                        </div>
                    }
                </div>
            </div>
            <div style={{ display: 'block', fontSize: '14px' }}>
                {renderOCR()}
            </div>
        </div>
    )
}

export default ImageCapture
