import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'

import CreateInstallation from './create'
import InstallationList from './list'
import InstallationView from './view'

const InstallationIndex = () => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.appInstallationIndex.path, routes.appInstallationCreate.path)} element={<CreateInstallation />} />
            <Route path={nestedRoutePath(routes.appInstallationIndex.path, routes.appInstallationView.path)} element={<InstallationView />} />
            <Route index element={<InstallationList />} />
        </Routes>
    )
}

export default InstallationIndex
