import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../constants/routes'

import AdminDashboard from './dashboard'
import AdminTrucksIndex from './trucks/index'
import AdminManualBuilderIndex from './manual-builder/index'
import AdminManualsIndex from './manuals/index'
import AdminAccountsIndex from './accounts/index'
import AdminInstallationIndex from './installations/index'
import AdminTemplateIndex from './templates/index'
import AdminTemplateGroupIndex from './templates/template-group/index'

import AdminNav from '../navs/admin-nav'

const AdminIndex = () => {
    return (
        <div id="admin">
            <AdminNav />
            <Routes>
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminTrucksIndex.path, true)} element={<AdminTrucksIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminManualsIndex.path, true)} element={<AdminManualsIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminManualBuilderIndex.path, true)} element={<AdminManualBuilderIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminAccountsIndex.path, true)} element={<AdminAccountsIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminTemplateIndex.path, true)} element={<AdminTemplateIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminInstallationIndex.path, true)} element={<AdminInstallationIndex />} />
                <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminTemplateGroupIndex.path, true)} element={<AdminTemplateGroupIndex />} />
                <Route index element={<AdminDashboard />} />
            </Routes>
        </div>
    )
}


export default AdminIndex
