import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

const mapStateToProps = (state, ownProps) => {
    return {
        adminInputGroup: state.adminInputGroups,
        adminQuestion: state.adminQuestions
    }
}

const AdminQuestionView = ({ dispatch, params, adminQuestion }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/questions', 'VIEW', params.questionId)))
    }, [dispatch, params.questionId])

    if (!adminQuestion || !adminQuestion.length) {
        return <div>Loading...</div>
    }

    return (
        <div className="content">
            <h1>Edit</h1>
            <h2>{adminQuestion[0].label}</h2>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminQuestionView))
