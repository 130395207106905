import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
// import { TZ_CONFIG } from '../../../constants/tz-config'

const mapStateToProps = (state, ownProps) => {
    return {
        roles: state.roles,
        account: state.account
    }
}

const CreateUser = ({ dispatch, navigate, params, roles, account }) => {
    const accountId = account.id
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('roles', 'LIST')))
    }, [dispatch])


    const submitFX = (formData, formId) => {
        formData.accountId = accountId
        dispatch(callApi(apiResourceEndpoint('user', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        navigate(buildRoutePath(routes.appTeamIndex.path, { accountId: accountId }))
    }

    const roleOptions = roles.slice()
        .sort((a, b) => {
            if (a.level > b.level) {
                return -1
            }
            return 1
        })
        .map(role => ({ value: role.id, label: role.name }))

    return (
        <div className="installation-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <h3>Use the form below to create a new user for this account.</h3>
                        <SHSForm extraClass="flex-form" submitFX={submitFX} successFX={successFX}>
                            <InputElement
                                type="text"
                                id="firstName"
                                label="First Name"
                                placeholder="John"
                                extraClass="flex-50"
                                required />
                            <InputElement
                                type="text"
                                id="lastName"
                                label="Last Name"
                                placeholder="Doe"
                                extraClass="flex-50"
                                required />
                            <InputElement
                                type="email"
                                id="email"
                                label="Email"
                                placeholder="user@domain.com"
                                extraClass="flex-50"
                                required />
                            <InputElement
                                type="valueSelect"
                                id="roleId"
                                label="Role"
                                options={roleOptions}
                                extraClass="flex-50"
                                required />
                        </SHSForm>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateUser))
