import React from 'react'
import { WebcamCapture } from './webcam-capture'

const ImageCaptureWebcam = () => {
    return (

        <div className="content">
            <div className="page-title">
                <h1>Image Capture using Webcam</h1>
            </div>
            <div>
                <WebcamCapture />
            </div>
        </div>
    )
}

export default ImageCaptureWebcam
