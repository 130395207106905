import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { Link } from 'react-router-dom'
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { createSelector } from '@reduxjs/toolkit'

import { PaginationWrapper } from 'shs-pagination'

const selectTemplate = (state, templateId) => state.adminInputTemplates.find(t => t.id === Number(templateId))
const selectInputGroups = state => state.adminTemplateGroups
const selectGroupPaginator = state => state.paginator.adminTemplateGroups

const makeAdminInputGroups = createSelector(
    [selectInputGroups, selectGroupPaginator],
    (adminInputGroups, paginator) => {
        if (paginator) {
            return adminInputGroups
                .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
                .sort((a, b) => {
                    if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                        return 1
                    }
                    return -1
                })
        }
        return adminInputGroups
    })

const mapStateToProps = (state, ownProps) => {
    const paginator = selectGroupPaginator(state)
    const adminInputTemplate = selectTemplate(state, ownProps.params.templateId)
    const adminInputGroups = makeAdminInputGroups(state)
    return { adminInputTemplate, adminInputGroups, paginator }
}

const AdminTemplateView = ({ dispatch, navigate, params, adminInputTemplate, adminInputGroups, paginator }) => {
    const [showAddGroup, setShowAddGroup] = useState(false)
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/input-templates', 'VIEW', params.templateId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/template-groups', 'LIST'), { filter: [`template_id|${params.templateId}`] }))
    }, [dispatch, params.templateId])

    const handlePaginationUpdate = (paginationParams) => {
        paginationParams.filter = [`template_id|${params.templateId}`]
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(`admin/template-groups`, 'LIST'), paginationParams))
    }

    const renderGroupRow = (group, index) => {
        return (
            <tr key={group.id}>
                <td className="index">{index}</td>
                <td className="main">{group?.groupTitle}</td>
                <td className="main">
                    <Link to={buildRoutePath(routes.adminTemplateGroupView.path, { groupId: group.groupId })}>
                        Manage Questions
                    </Link>
                </td>
            </tr>
        )
    }

    const renderGroups = () => {
        if (!paginator) {
            return null
        }

        let allGroups = []
        adminInputGroups?.forEach((group, idx) => {
            let index = idx + 1
            allGroups.push(renderGroupRow(group, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                <table className="table striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allGroups}
                    </tbody>
                </table>
            </PaginationWrapper>
        )
    }

    const submitFX = (formData, formId) => {
        formData.isActive = true
        formData.templateId = adminInputTemplate.id
        dispatch(callApi(apiResourceEndpoint('admin/input-groups', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        navigate(buildRoutePath(routes.adminTemplateGroupIndex.path))
        setShowAddGroup(false)
    }

    if (!adminInputTemplate || adminInputTemplate.length === 0) {
        return null
    }

    const renderAddGroup = () => {
        if (!showAddGroup) {
            return null
        }
        return (
            <div className="content-box">
                <h3>Create a New Template Section for {adminInputTemplate.name}</h3>
                <SHSForm extraClass="flex-form" submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowAddGroup(false)}>
                    <InputElement
                        type="text"
                        id="name"
                        label="Section Name"
                        placeholder="Basic Truck Information"
                        extraClass="flex-50"
                        required />
                    <InputElement
                        type="integer"
                        id="displayOrder"
                        label="Display Order"
                        placeholder="1"
                        extraClass="flex-50"
                        required />
                </SHSForm>
            </div>
        )
    }

    return (
        <div className="content">
            <div className="box">
                <h3>Template Sections</h3>
                <div className="content-box">
                    {renderGroups()}
                </div>
                { !showAddGroup &&
                    <span onClick={() => setShowAddGroup(!showAddGroup)}>
                        Add Group
                    </span>
                }
                {renderAddGroup()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminTemplateView))
