import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../constants/routes'

import AdminTemplateCreate from './create'
import AdminTemplateView from './view'
import AdminTemplateList from './list'
import AdminTemplateGroupIndex from './template-group/index'

const AdminTemplateIndex = () => {
    return (
        <>
            <div className="builder-view">
                <Routes>
                    <Route path={nestedRoutePath(routes.adminTemplateIndex.path, routes.adminTemplateCreate.path)} element={<AdminTemplateCreate />} />
                    <Route path={nestedRoutePath(routes.adminTemplateIndex.path, routes.adminTemplateView.path)} element={<AdminTemplateView />} />
                    <Route path={nestedRoutePath(routes.adminTemplateIndex.path, routes.adminTemplateGroupIndex.path, true)} element={<AdminTemplateGroupIndex />} />
                    <Route index element={<AdminTemplateList />} />
                </Routes>
            </div>
        </>
    )
}

export default withRouterProps(connect()(AdminTemplateIndex))
