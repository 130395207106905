import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, routes } from '../../../constants/routes'
import { createSelector } from '@reduxjs/toolkit'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import Modal from '../../utils/modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectInstallId = (state, installId) => installId
const selectInstallations = state => state.adminInstallations
const selectTemplateId = (state, templateId) => templateId
const selectTemplateGroups = state => state.templateGroups
const selectAnswers = state => state.adminAnswers
const selectQuestions = state => state.adminQuestions
const selectAnswerValues = state => state.answerValues
const selectGroupIds = (state, tGroups) => tGroups.map(group => group.groupId)
const selectGroupQuestions = state => state.groupQuestions

const makeInstallation = createSelector(
    [selectInstallations, selectInstallId],
    (installations, installId) => {
        return installations.find(installation => installation.id === installId)
    }
)

const makeAnswers = createSelector(
    [selectAnswers, selectInstallId],
    (answers, installId) => {
        return answers.filter(answer => answer.installationId === installId)
    }
)

const makeTemplateGroups = createSelector(
    [selectTemplateGroups, selectTemplateId],
    (templateGroups, templateId) => {
        return templateGroups.filter(group => group.templateId === templateId)
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1
                }
                return -1
            })
    }
)


const makeQuestionGroups = createSelector(
    [selectGroupQuestions, selectGroupIds],
    (groupQuestions, groupIds) => {
        return groupQuestions.filter(question => groupIds.includes(question.groupId))
    }
)

const makeQuestionsWithAnswers = createSelector(
    [selectQuestions, selectAnswers, selectAnswerValues],
    (questions, answers, answerValues) => {
        return questions.map(question => {
            const answer = answers.find(a => a.questionId === question.id)
            const answerValue = answerValues.find(av => av.answerId === answer?.id)
            return { ...question, answer, answerValue }
        })
    }
)

const mapStateToProps = (state, ownProps) => {
    const installId = Number(ownProps.params.installId)
    const installation = makeInstallation(state, installId)
    const answers = makeAnswers(state, installId)
    const questionsWithAnswers = makeQuestionsWithAnswers(state, answers)
    const templateGroups = makeTemplateGroups(state, installation?.templateId)
    const questionGroups = makeQuestionGroups(state, templateGroups)
    return {
        installation,
        questions: questionsWithAnswers,
        answers,
        templateGroups,
        questionGroups,
        answerValues: state.adminAnswerValues,
        images: state.images
    }
}

const AdminInstallationView = ({ dispatch, params, installation, questions, answers, answerValues, templateGroups, questionGroups, images }) => {
    const installId = Number(params.installId)
    const templateId = installation?.templateId
    const [closedGroupIds, setClosedGroupIds] = useState([])
    const [rejectAnswerValueId, setRejectAnswerValueId] = useState(null)
    const [activeImageId, setActiveImageId] = useState(null)

    useEffect(() => {
        if (templateId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('template-groups', 'LIST'), { filter: [`template_id|${templateId}`] }))
        }
    }, [dispatch, templateId])

    useEffect(() => {
        if (installId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/installations', 'VIEW', installId)))
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/questions', 'LIST'), { 'no-pagination': true }))
        }
    }, [dispatch, installId])

    const handleApprove = (answerValue) => {
        const payload = {
            status: 'APPROVE',
            answerValueId: answerValue.id
        }
        dispatch(callApi(apiResourceEndpoint('admin/installations', 'UPDATE', installation.id, 'approve-answer'), payload))
    }

    const handleReject = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/installations', 'UPDATE', installation.id, 'reject-answer'), formData, formId))
    }

    if (!installation) {
        return <div>No installation found</div>
    }

    const renderGroupQuestions = (gQuestions) => {
        let allQuestions = []
        gQuestions.forEach(gQuestion => {
            const groupQuestion = questions.find(question => question.id === gQuestion.questionId)
            const answer = answers.find(a => a.questionId === groupQuestion?.id && a.installationId === installId)
            const answerValue = answerValues.find(av => av.answerId === answer?.id)
            let answerDisplay = null
            let showQuestion = true
            switch (groupQuestion?._computed.typeValue) {
            case 'IMAGE':
                const image = images.find(img => img.id === answerValue?.imageId)
                if (image) {
                    answerDisplay = (
                        <div onClick={() => setActiveImageId(answer.id)} className="image-wrapper" style={{ marginTop: '1rem' }}>
                            <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`installations`, 'VIEW', installId, `image/${answer.id}`).url + '?style=thumbnail'} alt="answer value" />
                        </div>
                    )
                }
                break
            case 'INFORMATIONAL':
                showQuestion = false
                break
            default:
                answerDisplay = <>{answerValue?.textValue}</>
            }
            if (showQuestion && groupQuestion) {
                allQuestions.push(
                    <div className="answer-review" key={`gquestion-${groupQuestion.id}`}>
                        <div className="question-answer">
                            <span className="question">{groupQuestion.label}</span>
                            <div className="answer">
                                {answerDisplay}
                            </div>
                        </div>
                        <div className="actions">
                            <FontAwesomeIcon className={`${answerValue?._computed.statusValue === 'APPROVED' ? 'approve' : ''}`} icon="circle-check" onClick={() => handleApprove(answerValue)} title="Approve" />
                            <FontAwesomeIcon className={`${answerValue?._computed.statusValue === 'REJECTED' ? 'reject' : ''}`} icon="circle-xmark" onClick={() => setRejectAnswerValueId(answerValue.id)} title="Reject" />
                        </div>
                    </div>
                )
            }
        })
        return allQuestions
    }

    const toggleGroupOpenClose = (groupId) => {
        if (closedGroupIds.includes(groupId)) {
            setClosedGroupIds(closedGroupIds.filter(id => id !== groupId))
        } else {
            setClosedGroupIds([...closedGroupIds, groupId])
        }
    }

    const renderTemplateGroups = () => {
        let allGroups = []
        templateGroups.forEach(group => {
            const gQuestions = questionGroups.filter(question => question.groupId === group.groupId)
            allGroups.push(
                <div key={group.id} className="qGroup">
                    <h3 onClick={() => toggleGroupOpenClose(group.id)}>{group.groupTitle}</h3>
                    {closedGroupIds.includes(group.id) ? null : renderGroupQuestions(gQuestions)}
                </div>
            )
        })
        return allGroups
    }


    return (
        <div className="installation-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper admin-box" style={{ width: '100%' }}>
                        <Link to={routes.adminInstallationIndex.path}>Back to Installations</Link>
                        <h2>Installation</h2>
                        {renderTemplateGroups()}
                    </div>
                </div>
            </div>
            {rejectAnswerValueId && <Modal closeAction={() => setRejectAnswerValueId(null)}>
                <h2>Notes for Installer</h2>
                <SHSForm submitFX={handleReject} successFX={() => setRejectAnswerValueId(null)}>
                    <InputElement
                        type="textarea"
                        id="optimusNotes" />
                    <InputElement
                        type="hidden"
                        id="answerValueId"
                        default={rejectAnswerValueId} />
                </SHSForm>
            </Modal>}
            {activeImageId &&
                <Modal closeAction={() => setActiveImageId(null)}>
                    <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`installations`, 'VIEW', installId, `image/${activeImageId}`).url + '?style=large'} alt="answer value" />
                </Modal>
            }
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminInstallationView))
