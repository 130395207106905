import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { Link } from 'react-router-dom'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

const mapStateToProps = (state) => {
    return {
        templates: state.templates
    }
}

const CreateInstallation = ({ dispatch, navigate, templates }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('templates', 'LIST')))
    }, [dispatch])

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('installations', 'CREATE'), formData, formId))
    }

    const successFX = (installationId) => {
        navigate(buildRoutePath(routes.appInstallationView.path, { installId: installationId }))
        // console.log('we got an installation id')
        // console.log(installationId)
    }

    const templateOptions = templates.map(template => ({ label: template.name, value: template.id }))

    return (
        <div className="installation-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <Link to={routes.appIndex.path} className="back-link">
                            &larr; Back
                        </Link>
                        <h1>Create Installation</h1>
                        <p>
                            To create an installation, please take a picture of the VIN or enter in the field below.
                        </p>
                        <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Start Installation">
                            <InputElement
                                type="text"
                                id="vin"
                                label="VIN"
                                placeholder="i.e. 1X1X11ABCD12345"
                                required />
                            <InputElement
                                type="valueSelect"
                                id="templateId"
                                label="Template"
                                options={templateOptions}
                                required />
                        </SHSForm>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateInstallation))
