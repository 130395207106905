import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import formReducer from './form'
import genericSlice from './generic-slice'
import pagerReducer from './pager'
import uiReducer from './ui'

import { INITIAL_TRUCK_DATA } from '../constants/initial-reducer-data'

// Breaking manuals down into their pieces
// import { MANUAL_DATA } from '../constants/manual-data'
// import { MANUAL_GROUP_DATA } from '../constants/manual-group-data'
// import { MANUAL_STEP_DATA } from '../constants/manual-step-data'
// import { MANUAL_SUBSTEP_DATA } from '../constants/manual-substep-data'

const indexReducer = {
    account: genericSlice('ACCOUNT', {}),
    adminAnswers: genericSlice('ADMIN_ANSWERS'),
    adminAnswerValues: genericSlice('ADMIN_ANSWER_VALUES'),
    adminUser: genericSlice('ADMIN_USER'),
    adminRoles: genericSlice('ADMIN_ROLE'),
    adminInputGroups: genericSlice('ADMIN_INPUT_GROUPS'),
    adminInstallations: genericSlice('ADMIN_INSTALLATIONS'),
    adminInputTemplates: genericSlice('ADMIN_INPUT_TEMPLATES'),
    adminTemplateGroups: genericSlice('ADMIN_TEMPLATE_GROUPS'),
    adminQuestions: genericSlice('ADMIN_QUESTIONS'),
    answers: genericSlice('ANSWERS'),
    answerValues: genericSlice('ANSWER_VALUES'),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    configs: genericSlice('CONFIG_VALUES'),
    forms: formReducer,
    groupQuestions: genericSlice('GROUP_QUESTIONS'),
    images: genericSlice('IMAGES'),
    installations: genericSlice('INSTALLATIONS'),
    // manualsOld: genericSlice('MANUALS_OLD', INITIAL_MANUAL_DATA),
    manuals: genericSlice('ADMIN_MANUALS'),
    manualGroups: genericSlice('MANUAL_GROUPS'),
    manualSteps: genericSlice('MANUAL_STEPS'),
    manualSubsteps: genericSlice('MANUAL_SUBSTEPS'),
    paginator: pagerReducer,
    questions: genericSlice('QUESTIONS'),
    questionExamples: genericSlice('QUESTION_EXAMPLES'),
    templates: genericSlice('INPUT_TEMPLATES'),
    templateGroups: genericSlice('TEMPLATE_GROUPS'),
    trucks: genericSlice('TRUCKS', INITIAL_TRUCK_DATA),
    roles: genericSlice('ROLES'),
    teams: genericSlice('TEAMS'),
    ui: uiReducer,
    user: genericSlice('USER', {})
}

export default indexReducer
