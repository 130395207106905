import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { Link } from 'react-router-dom'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'
// import { PaginationWrapper } from 'shs-pagination'
// import { createSelector } from '@reduxjs/toolkit'


const mapStateToProps = (state, ownProps) => {
    return {
        adminInputGroup: state.adminInputGroups.find(g => g.id === Number(ownProps.params.groupId)),
        configs: state.configs,
        adminQuestions: state.adminQuestions
    }
}

const AdminTemplateGroupView = ({ dispatch, params, adminInputGroup, paginator, adminQuestions, configs }) => {
    const [questions, setQuestions] = useState(adminQuestions)
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/input-groups', 'VIEW', params.groupId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('configs', 'LIST', null), { filter: ['field|type_id'] }))
    }, [dispatch, params.groupId])

    console.log(adminQuestions)

    // useEffect(() => {
    //     if (adminInputGroup && adminInputGroup[0] && Array.isArray(adminInputGroup[0]._computed.questionIds)
    //     ) {
    //         dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/questions', 'LIST'), { groupId: adminInputGroup[0].id }))
    //     }
    // }, [dispatch, adminInputGroup])

    const typeOptions = () => {
        if (!configs || !adminInputGroup) {
            return null
        }
        return configs.map(config => {
            return ({ value: config.id, label: config.value })
        })
    }

    const renderQuestionRow = (question, index) => {
        return (
            <tr key={question.id}>
                <td className="index">{index}</td>
                <td className="main">{question.label}</td>
                <td className="main">{question.typeId}</td>
                <td className="main">
                    <Link to={buildRoutePath(routes.adminQuestionView.path, { questionId: question.id })}>
                        Edit
                    </Link></td>
            </tr>
        )
    }

    // const handlePaginationUpdate = (paginationParams) => {
    //     paginationParams.filter = [`id|${adminQuestions.id}`]
    //     dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/questions', 'LIST'), paginationParams))
    // }

    const renderQuestions = () => {
        // if (!paginator) {
        //     return null
        // }
        let allQuestions = []
        adminQuestions?.forEach((question) => {
            allQuestions.push(renderQuestionRow(question))
        })
        return (
            <table className="table striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Label</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {allQuestions}
                </tbody>
            </table>
        )
    }

    const submitFX = (formData, formId) => {
        formData.groupId = adminInputGroup.id
        dispatch(callApi(apiResourceEndpoint('admin/questions', 'CREATE'), formData, formId))
            .then(response => {
                if (response && response.data) {
                    setQuestions([...questions, response.data])
                }
            })
    }

    const successFX = () => {
        console.log('success')
    }

    if (!adminInputGroup) {
        return <div>Loading...</div>
    }

    return (
        <div className="content">
            <div className="box">
                <h3>Create Questions for {adminInputGroup.name}</h3>
                <SHSForm extraClass="flex-form" submitFX={submitFX} successFX={successFX}>
                    <InputElement
                        type="text"
                        id="label"
                        label="Question Title"
                        placeholder="Date of Completion"
                        extraClass="flex-50"
                        required />
                    <InputElement
                        type="text"
                        id="instructions"
                        label="Instructions"
                        placeholder="Further instructions for the user"
                        extraClass="flex-50" />
                    <InputElement
                        type="valueSelect"
                        id="typeId"
                        label="Type"
                        placeholder="Select a type"
                        options={typeOptions()}
                        extraClass="flex-50"
                        required />
                    <InputElement
                        type="text"
                        id="options"
                        label="Options(not required)"
                        placeholder="Prompts or options for the user"
                        extraClass="flex-50" />
                    <InputElement
                        type="integer"
                        id="displayOrder"
                        label="Display Order"
                        placeholder="1"
                        extraClass="flex-50"
                        required />
                </SHSForm>
            </div>
            <div className="box">
                <h3>Group Questions</h3>
                {renderQuestions()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminTemplateGroupView))
