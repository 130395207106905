import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
// import { TZ_CONFIG } from '../../../constants/tz-config'

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        adminInputGroups: state.adminInputGroups,
        adminInputTemplates: state.adminInputTemplates,
        adminQuestions: state.adminQuestions
    }
}

const AdminTemplateCreate = ({ dispatch, navigate, params, roles, account }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/input-groups', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/questions', 'LIST')))
    }, [dispatch])


    const submitFX = (formData, formId) => {
        formData.isActive = true
        dispatch(callApi(apiResourceEndpoint('admin/input-templates', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        navigate(buildRoutePath(routes.adminInstallationIndex.path))
    }

    return (
        <div className="content">
            <h3>Use the form below to start a new installation manual</h3>
            <SHSForm extraClass="flex-form" submitFX={submitFX} successFX={successFX}>
                <InputElement
                    type="text"
                    id="name"
                    label="Template Name"
                    placeholder="Kenworth T680"
                    extraClass="flex-50"
                    required />
                <InputElement
                    type="text"
                    id="manualId"
                    label="Manual ID"
                    placeholder="12345"
                    extraClass="flex-50" />
            </SHSForm>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminTemplateCreate))
