import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../../constants/routes'

import AdminTemplateGroupView from './view'
import AdminQuestionView from './question-view'

const AdminTemplateGroupIndex = () => {
    return (
        <>
            <div className="builder-view">
                <Routes>
                    <Route path={nestedRoutePath(routes.adminTemplateGroupIndex.path, routes.adminTemplateGroupView.path)} element={<AdminTemplateGroupView />} />
                    <Route path={nestedRoutePath(routes.adminTemplateGroupIndex.path, routes.adminQuestionView.path)} element={<AdminQuestionView />} />
                    <Route index element={<AdminTemplateGroupView />} />
                </Routes>
            </div>
        </>
    )
}

export default withRouterProps(connect()(AdminTemplateGroupIndex))
