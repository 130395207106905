import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { createSelector } from '@reduxjs/toolkit'

import { PaginationWrapper } from 'shs-pagination'

const selectTemplates = state => state.adminInputTemplates
const selectTemplatePaginator = state => state.paginator.adminInputTemplates

const makeTemplates = createSelector(
    [selectTemplates, selectTemplatePaginator],
    (templates, paginator) => {
        if (paginator) {
            return templates
                .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
                .sort((a, b) => {
                    if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                        return 1
                    }
                    return -1
                })
        }
        return []
    }
)

const mapStateToProps = (state) => {
    const paginator = selectTemplatePaginator(state)
    const templates = makeTemplates(state)
    return { paginator, templates }
}

const AdminTemplateList = ({ dispatch, templates, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/input-templates', 'LIST')))
    }, [dispatch])

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(`admin/input-templates`, 'LIST'), paginationParams))
    }

    const renderTemplates = () => {
        if (!paginator) {
            return null
        }

        let allTemplates = []
        templates.forEach(template => {
            allTemplates.push(
                <tr key={template.id} className="manual-list-item">
                    <td>{template.name}</td>
                    <td>{template._computed.manualTitle}</td>
                    <td>
                        <Link to={buildRoutePath(routes.adminTemplateView.path, { templateId: template.id })}>
                            Manage
                        </Link>
                    </td>
                </tr>
            )
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Manual</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTemplates}
                    </tbody>
                </table>
            </PaginationWrapper>
        )
    }

    return (
        <div className="content">
            <div className="page-title">
                <h1>Templates</h1>
                <Link to={routes.adminTemplateCreate.path}>
                    + Create New Template
                </Link>
            </div>
            <div className="content-box">
                {renderTemplates()}
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(AdminTemplateList)
