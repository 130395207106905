import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'

const AdminDashboard = () => {
    return (
        <div className="content-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <h1>Admin Dashboard</h1>
                        <Link to={routes.adminInstallationIndex.path}>View Installations</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard
