import React from 'react'
import { connect } from 'react-redux'
import InputElement from '../../utils/input'

const mapStateToProps = (state, ownProps) => {
    const answer = state.answers.find(a => a.questionId === ownProps.question.id && a.installationId === ownProps.installationId)
    let answerValue = null
    if (answer) {
        answerValue = state.answerValues.find(av => av.answerId === answer.id)
    }
    return {
        answer,
        answerValue
    }
}

const TextQuestion = ({ question, answer, answerValue }) => {
    const renderNotes = () => {
        if (answerValue?._computed.statusValue === 'REJECTED' && answerValue.notes) {
            return (
                <div className="rejected-notes">
                    <span className="error-label"> Error</span>
                    <p className="notes">
                        {answerValue.notes}
                    </p>
                </div>
            )
        }
        return null
    }

    let inputId = `answer_${answer?.id}`
    if (answerValue) {
        inputId = inputId + '_' + answerValue.id
    }

    return (
        <>
            <InputElement
                type="text"
                id={inputId}
                default={answerValue?.textValue}
                label={question?.label}
                infoIcon={question?.instructions ? 'circle-info' : null}
                instructions={question?.instructions}
                placeholder={question?.placeholder}
                extraClass={`with-status ${answer?._computed.statusValue.toLowerCase()}`} />
            {renderNotes()}
        </>
    )
}

export default connect(mapStateToProps)(TextQuestion)
