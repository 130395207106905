import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { callFileApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'
import TextQuestion from './text-question'
import OptionSelectQuestion from './option-select-question'
import ImageQuestion from './image-question'
import InfoQuestion from './info-question'

import { createSelector } from '@reduxjs/toolkit'

const selectQuestionIds = (state, questionIds) => questionIds
const selectQuestions = state => state.questions

const makeQuestions = createSelector(
    [selectQuestions, selectQuestionIds],
    (questions, questionIds) => {
        return questions.filter(question => questionIds.includes(question.id))
            .sort((a, b) => {
                // based on the index of the questionids
                return questionIds.indexOf(a.id) - questionIds.indexOf(b.id)
            })
    }
)

const mapStateToProps = (state, ownProps) => {
    return {
        questions: makeQuestions(state, ownProps.questionIds)
    }
}


const GroupQuestions = ({ dispatch, activeGroup, installation, groupId, questionIds, questions, onSetActiveGroupId }) => {
    const questionFilter = questionIds.map(id => `id|${id}`).join(',')

    useEffect(() => {
        if (questionFilter !== '') {
            const filter = questionFilter.split(',')
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('questions', 'LIST'), { 'filter': filter, 'filterMethod': 'OR', 'no-pagination': true }))
        }
    }, [dispatch, questionFilter])

    const renderQuestions = () => {
        let allQuestions = []
        questions.forEach(question => {
            let questionComponent = null
            switch (question._computed.typeValue) {
            case 'INFORMATIONAL':
                questionComponent = <InfoQuestion installationId={installation.id} question={question} />
                break
            case 'TEXT':
            case 'NUMERIC':
                questionComponent = <TextQuestion installationId={installation.id} question={question} />
                break
            case 'IMAGE':
                questionComponent = <ImageQuestion installationId={installation.id} question={question} />
                break
            case 'FILE':
                questionComponent = <div>I am a file question</div>
                break
            case 'OPTION_SELECT':
                questionComponent = <OptionSelectQuestion installationId={installation.id} question={question} />
                break
            default:
                // do nothing
            }
            allQuestions.push(
                <div key={question.id}>
                    {questionComponent}
                </div>
            )
        })
        return allQuestions
    }

    const submitFX = (formData, formId) => {
        console.log(formData, formId)
        dispatch(callFileApi(apiResourceEndpoint('installations', 'UPDATE', installation.id, 'answers'), formData, formId))
    }

    const successFX = () => {
        onSetActiveGroupId(null)
    }

    return (
        <>
            <div className="question-group">
                <span className="clickable" onClick={() => onSetActiveGroupId(null)}>
                    &larr; Back
                </span>
                <h2>{activeGroup.groupTitle}</h2>
                <SHSForm submitFX={submitFX} successFX={successFX} useFormData={true} buttonLabel="Save">
                    {renderQuestions()}
                </SHSForm>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(GroupQuestions)
