import React from 'react'
import { connect } from 'react-redux'
import InputElement from '../../utils/input'

const mapStateToProps = (state, ownProps) => {
    const answer = state.answers.find(a => a.questionId === ownProps.question.id && a.installationId === ownProps.installationId)
    let answerValue = null
    if (answer) {
        answerValue = state.answerValues.find(av => av.answerId === answer.id)
    }
    return {
        answer,
        answerValue
    }
}

const InfoQuestion = ({ question, answer, answerValue }) => {
    let inputId = `answer_${answer?.id}`
    if (answerValue) {
        inputId = inputId + '_' + answerValue.id
    }

    return (
        <div className="input-content input-wrapper">
            <span className="form-label">{question.label}</span>
            <p>{question.instructions}</p>
            <InputElement
                type="hidden"
                id={inputId}
                label={null}
                extraClass="d-none"
                default="READ" />
        </div>
    )
}

export default connect(mapStateToProps)(InfoQuestion)
