import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { saveUiState } from '../../reducers/ui'

const mapStateToProps = (state) => {
    return {
        user: state.user,
        showSidebar: state.ui.showSidebar
    }
}

const AppNav = ({ dispatch, user, showSidebar }) => {
    const [ isOpen, setIsOpen ] = useState(false)
    const renderMenuOverlay = () => {
        return (
            <div className={`menu-overlay ${isOpen ? 'active' : ''}`}>
                <div className="closer" onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon="times" />
                </div>
                <div className="menu">
                    <div className="links">
                        {/* <Link to={routes.publicIndex.path}>Home</Link> */}
                        <Link onClick={() => setIsOpen(false)} to={routes.appIndex.path}>Dashboard</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.appUserIndex.path}>My Profile</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.appTeamIndex.path}>My Team</Link>
                        { user?._computed?.roleLevel >= 1000 && <Link onClick={() => setIsOpen(false)} to={routes.adminIndex.path}>Admin</Link> }
                        {/* <Link onClick={() => setIsOpen(false)} to={routes.appSettingsIndex.path}>Settings</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.appCaptureImage.path}>Image Capture</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.appCaptureImageWebcam.path}>Image Capture Webcam</Link> */}
                        <Link onClick={() => setIsOpen(false)} to={routes.authLogout.path}>Logout</Link>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/imgs/optimus-gear-only-white.svg" alt="Optimus Technologies" height="50" />
                    </div>
                </div>
            </div>
        )
    }

    // const showTruckSidebar = () => {
    //     dispatch(saveUiState({ key: 'showSidebar', value: true }))
    // }

    // const renderTruckTitle = () => {
    //     if (showSidebar) {
    //         // TODO: Should this just be a back button or vary based on where we are?
    //         return (
    //             <Link onClick={() => setIsOpen(false)} to={routes.appIndex.path}>
    //                 <FontAwesomeIcon icon="arrow-left" />
    //                 Dashboard
    //             </Link>
    //         )
    //     }
    //     return (
    //         <span onClick={showTruckSidebar}>
    //             <FontAwesomeIcon icon="arrow-left" />
    //             Step List
    //         </span>
    //     )
    // }

    return (
        <>
            <div className={`nav ${isOpen ? 'open' : ''}`}>
                <div className="container nav-wrapper">
                    {/* <div className="nav-title">
                        <Routes>
                            <Route path={nestedRoutePath(routes.appIndex.path, routes.appInstallationView.path, true)} element={renderTruckTitle()} />
                        </Routes>
                    </div> */}
                    <div className="menu-wrapper">
                        <span className="hamburger" onClick={() => setIsOpen(!isOpen)}>
                            <FontAwesomeIcon icon="bars" />
                        </span>
                    </div>
                </div>
            </div>
            {renderMenuOverlay()}
        </>
    )
}

export default connect(mapStateToProps)(AppNav)
