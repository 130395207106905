import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { withRouterProps } from '../../utils/with-router-props'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'

import { createSelector } from '@reduxjs/toolkit'
import { PaginationWrapper } from 'shs-pagination'

const selectInstallations = state => state.adminInstallations
const selectInstallPager = state => state.paginator.adminInstallations

const makeInstallations = createSelector(
    [selectInstallations, selectInstallPager],
    (installations, paginator) => {
        if (paginator) {
            return installations
                .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
                .sort((a, b) => {
                    if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                        return 1
                    }
                    return -1
                })
        }
        return []
    }
)

const mapStateToProps = (state) => {
    return {
        installations: makeInstallations(state),
        paginator: selectInstallPager(state)
    }
}

const AdminInstallationList = ({ dispatch, installations, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/installations', 'LIST')))
    }, [dispatch])

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(`installations`, 'LIST'), paginationParams))
    }

    const renderCards = () => {
        if (!paginator) {
            return null
        }
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                <div className="card-grid">
                    {installations.map((install, idx) => {
                        return (
                            <Link
                                to={buildRoutePath(routes.adminInstallationView.path, { installId: install.id })}
                                className="card" key={install.id}>
                                <div className="title">
                                    {install.vin}
                                </div>
                                <div className="status">
                                    {install._computed.statusDisplay}
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </PaginationWrapper>
        )
    }
    return (
        <div className="content-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <h2>Current Installations</h2>
                        {renderCards()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminInstallationList))
