import React, { useState } from 'react'
import { connect } from 'react-redux'
import { apiResourceEndpoint } from '../../../constants/routes'

import Modal from '../../utils/modal'

const mapStateToProps = (state, ownProps) => {
    return {
        image: state.images.find(img => img.id === ownProps.example.imageId)
    }
}

const ExamplePhoto = ({ example, image, installationId }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <div className="example-photo image-wrapper" style={{ marginTop: '1rem' }} onClick={()=> setShowModal(true)}>
                <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`questions`, 'VIEW', example.questionId, `example-photo/${image.id}`).url + '?style=thumbnail'} alt="answer value" />
            </div>
            { showModal &&
                <Modal closeAction={() => setShowModal(false)}>
                    <p>Good example: {example.caption}</p>
                    <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`questions`, 'VIEW', example.questionId, `example-photo/${image.id}`).url + '?style=large'} alt="answer value" />
                </Modal>
            }
        </>
    )
}

export default connect(mapStateToProps)(ExamplePhoto)
