import React, { useState } from 'react'
import { connect } from 'react-redux'

import InputElement from '../../utils/input'
import { apiResourceEndpoint } from '../../../constants/routes'
import Modal from '../../utils/modal'
import ExamplePhoto from './example-photo'

const mapStateToProps = (state, ownProps) => {
    const answer = state.answers.find(a => a.questionId === ownProps.question.id && a.installationId === ownProps.installationId)
    let answerValue = null
    if (answer) {
        answerValue = state.answerValues.find(av => av.answerId === answer.id)
    }
    let image = null
    if (answerValue) {
        image = state.images.find(img => img.id === answerValue.imageId)
    }
    return {
        answer,
        answerValue,
        image,
        examples: state.questionExamples.filter(example => example.questionId === ownProps.question.id)
    }
}

const ImageQuestion = ({ dispatch, question, answer, answerValue, image, installationId, examples }) => {
    const [showModal, setShowModal] = useState(false)
    const [showExamples, setShowExamples] = useState(false)

    const renderNotes = () => {
        if (answerValue?._computed.statusValue === 'REJECTED' && answerValue.notes) {
            return (
                <div className="rejected-notes">
                    <span className="error-label"> Error</span>
                    <p className="notes">
                        {answerValue.notes}
                    </p>
                </div>
            )
        }
        return null
    }

    let inputId = `answer_${answer?.id}`
    if (answerValue) {
        inputId = inputId + '_' + answerValue.id
    }

    const renderExamples = () => {
        if (examples.length === 0) {
            return null
        }
        return (
            <div className="image-examples">
                <span className="clickable" onClick={() => setShowExamples(!showExamples)}>
                    {showExamples ? 'Hide' : 'Show'} Example Photos
                </span>
                { showExamples &&
                    <div className="photos">
                        {examples.map(example => {
                            return <ExamplePhoto key={example.id} example={example} />
                        })}
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <InputElement
                accept="image/*"
                type="file"
                id={inputId}
                infoIcon={question?.instructions ? 'circle-info' : null}
                instructions={question?.instructions}
                extraClass={`with-status ${answer?._computed.statusValue.toLowerCase()}`}
                label={question.label} />
            {image &&
                <div className="image-wrapper" onClick={() => setShowModal(true)}>
                    <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`installations`, 'VIEW', installationId, `image/${answer.id}`).url + '?style=thumbnail'} alt="answer value" />
                </div>
            }
            {renderExamples()}
            {renderNotes()}
            {showModal &&
                <Modal closeAction={() => setShowModal(false)}>
                    <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`installations`, 'VIEW', installationId, `image/${answer.id}`).url + '?style=large'} alt="answer value" />
                </Modal>
            }
        </>
    )
}

export default connect(mapStateToProps)(ImageQuestion)
