import React from 'react'

const TemplateGroupTile = ({ group, questionIds, answered, onSetActiveGroupId }) => {
    let percentage = 0
    if (questionIds.length > 0) {
        percentage = answered.length / questionIds.length * 100
    }

    return (
        <div className="template-group-tile" onClick={() => onSetActiveGroupId(group.groupId)}>
            <div className="title-wrapper">
                <span className="title">{group.groupTitle}</span>
            </div>
            <div className="answer-progress">
                <div className="progress" style={{ width: `${percentage > 100 ? 100 : percentage}%` }} />
            </div>
            <div className="progress-status">
                { percentage === 0
                    ? <span>Not started</span>
                    : <span>{answered.length} of {questionIds.length} answered</span>
                }
            </div>
        </div>
    )
}

export default TemplateGroupTile
