import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, routes } from '../../../constants/routes'
import { createSelector } from '@reduxjs/toolkit'

import TemplateGroupTile from './template-group-tile'
import GroupQuestions from './group-questions'

const selectInstallId = (state, installId) => installId
const selectInstallations = state => state.installations
const selectTemplateId = (state, templateId) => templateId
const selectTemplateGroups = state => state.templateGroups
const selectGroupIds = (state, tGroups) => tGroups.map(group => group.groupId)
const selectGroupQuestions = state => state.groupQuestions
const selectAnswers = state => state.answers
// const selectQuestions = state => state.questions

const makeInstallation = createSelector(
    [selectInstallations, selectInstallId],
    (installations, installId) => {
        return installations.find(installation => installation.id === installId)
    }
)

const makeTemplateGroups = createSelector(
    [selectTemplateGroups, selectTemplateId],
    (templateGroups, templateId) => {
        return templateGroups.filter(group => group.templateId === templateId)
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1
                }
                return -1
            })
    }
)
const makeQuestionGroups = createSelector(
    [selectGroupQuestions, selectGroupIds],
    (groupQuestions, groupIds) => {
        return groupQuestions.filter(question => groupIds.includes(question.groupId))
    }
)

const makeAnswers = createSelector(
    [selectAnswers, selectInstallId],
    (answers, installId) => {
        return answers.filter(answer => answer.installationId === installId)
    }
)

const mapStateToProps = (state, ownProps) => {
    const installation = makeInstallation(state, Number(ownProps.params.installId))
    const answers = makeAnswers(state, Number(ownProps.params.installId))
    const templateGroups = makeTemplateGroups(state, installation?.templateId)
    const questionGroups = makeQuestionGroups(state, templateGroups)
    return {
        installation,
        templateGroups,
        questionGroups,
        answers
    }
}

const InstallationView = ({ dispatch, params, installation, templateGroups, questionGroups, answers }) => {
    const [activeGroupId, setActiveGroupId] = useState(null)
    const installId = params.installId
    const templateId = installation?.templateId
    const [width, setWidth] = useState(window.innerWidth)
    const [active, setActive] = useState('')

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    useEffect(() => {
        if (installId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('installations', 'VIEW', installId)))
        }
    }, [dispatch, installId])

    useEffect(() => {
        if (templateId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('template-groups', 'LIST'), { filter: [`template_id|${templateId}`] }))
        }
    }, [dispatch, templateId])

    if (!installation) {
        return <div>No installation found</div>
    }

    const handleActive = () => {
        if (width > 992 && active === '') {
            setActive('active')
        }
    }

    const renderTemplateGroups = () => {
        if (activeGroupId && width < 992) {
            return null
        }

        let allGroups = []
        templateGroups.forEach(group => {
            const gQuestions = questionGroups.filter(question => question.groupId === group.groupId)
            const questionIds = gQuestions.map(question => question.questionId)
            const groupAnswers = answers.filter(answer => questionIds.includes(answer.questionId))
            const answered = groupAnswers.filter(answer => answer._computed.statusValue === 'SUBMITTED' || answer._computed.statusValue === 'APPROVED')
            allGroups.push(
                <TemplateGroupTile key={group.id} group={group} questionIds={questionIds} answered={answered} onSetActiveGroupId={setActiveGroupId} />
            )
        })
        return allGroups
    }

    const renderGroupQuestions = () => {
        if (!activeGroupId && width < 992) {
            return null
        } else if (!activeGroupId) {
            return (
                'Please select a topic for a list of questions'
            )
        }
        const activeGroup = templateGroups.find(group => group.groupId === activeGroupId)
        const gQuestions = questionGroups.filter(question => question.groupId === activeGroupId)
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1
                }
                return -1
            })
        const questionIds = gQuestions.map(question => question.questionId)
        return (
            <GroupQuestions
                installation={installation}
                onSetActiveGroupId={setActiveGroupId}
                activeGroup={activeGroup}
                groupId={activeGroupId}
                questionIds={questionIds} />
        )
    }

    return (
        <div className="installation-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        {/* <div className={`dashboard-wrapper ${ active }`} style={{ width: '100%' }}> */}
                        <Link to={routes.appIndex.path}>&larr; Back</Link>
                        <h1>Installation</h1>
                        <h2>VIN: {installation.vin}</h2>
                        <div className="installation-render-wrapper">
                            <div className={`render-template-groups ${activeGroupId && width < 992 && 'mobile-hide'}`} onClick={handleActive}>
                                {renderTemplateGroups()}
                            </div>
                            <div className={`render-group-questions ${activeGroupId ? 'active' : ''} ${width < 992 && 'mobile-show'}`} onClick={handleActive}>
                                {renderGroupQuestions()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(InstallationView))
