import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { withRouterProps } from '../utils/with-router-props'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../constants/routes'
import { fetchApiDataIfNeeded } from '../../actions/api'
import { createSelector } from '@reduxjs/toolkit'
// import PageHeader from '../shared/layout/page-header'

import { PaginationWrapper } from 'shs-pagination'

const selectInstallations = state => state.installations
const selectInstallPager = state => state.paginator.installations

const makeInstallations = createSelector(
    [selectInstallations, selectInstallPager],
    (installations, paginator) => {
        if (paginator) {
            return installations
                .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
                .sort((a, b) => {
                    if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                        return 1
                    }
                    return -1
                })
        }
        return []
    }
)

const mapStateToProps = (state) => {
    return {
        installations: makeInstallations(state),
        paginator: selectInstallPager(state)
    }
}

const Dashboard = ({ dispatch, installations, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('installations', 'LIST')))
    }, [dispatch ])


    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(`installations`, 'LIST'), paginationParams))
    }

    const renderInstallations = () => {
        if (!paginator) {
            return null
        }
        let allInstalls = []
        installations.forEach(install => {
            allInstalls.push(
                <Link
                    to={buildRoutePath(routes.appInstallationView.path, { installId: install.id })}
                    className="card" key={install.id}>
                    <div className="title">
                        VIN: {install.vin}
                    </div>
                    <div className="status">
                        {install._computed.statusDisplay}
                    </div>
                </Link>
            )
        })
        return (
            <>
                <PaginationWrapper
                    paginator={paginator}
                    controls={{ search: { enabled: true, collapsed: true } }}
                    callbackFX={handlePaginationUpdate}>
                    <div className="card-grid">
                        {allInstalls}
                    </div>
                </PaginationWrapper>
            </>
        )
    }
    return (
        <div className="content-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <h1>Installations</h1>
                        {renderInstallations()}
                        <Link to={routes.appInstallationCreate.path} className="btn blue main" key="add-item">
                            <div className="title">Start a new Truck</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(Dashboard))
