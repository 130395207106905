import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

const Home = () => {
    return (
        <div className="container">
            <div className="login-wrapper">
                <img src="/imgs/optimus-technologies.svg" alt="Optimus Technologies" width="300" />
                <h1>Optimus Installation App</h1>
                <Link to={routes.authLogin.path}>Login</Link>
            </div>
        </div>
    )
}

export default Home
