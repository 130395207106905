import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { withRouterProps } from '../../utils/with-router-props'
import { getAuth } from 'firebase/auth'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import EditableDisplayField from '../../utils/editable-display-field'
import ManageAuth from './manage-auth'

const selectUser = state => state.user

const mapStateToProps = (state) => {
    const user = selectUser(state)
    return {
        user,
        account: state.user._computed.accountTypeName,
        role: state.user._computed.roleName
    }
}

const AppUserView = ({ dispatch, params, navigate, user, account, role }) => {
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const fireauth = getAuth()
    const userId = params.userId
    const prevUser = usePrevious(user)

    useEffect(() => {
        if (prevUser && !user) {
            setShouldRedirect(true)
        }
    }, [user, prevUser])

    function usePrevious(value) {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])
        return ref.current
    }

    useEffect(() => {
        if (userId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user', 'VIEW')))
        }
    }, [dispatch, userId])

    if (shouldRedirect) {
        navigate(buildRoutePath(routes.appIndex.path))
    }

    if (!user) {
        return null
    }

    return (
        <div className="installation-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper" style={{ width: '100%' }}>
                        <div className="header">
                            <h2>{user.firstName} {user.lastName}'s Profile</h2>
                            <h3>{account}</h3>
                            <h3>{role}</h3>
                            <h4>Click on a field to edit</h4>
                        </div>
                        <div className="content-box">
                            <EditableDisplayField
                                label="First Name"
                                display={user.firstName}
                                endpoint="user"
                                dataObj={user}
                                fields={[{
                                    field: 'firstName',
                                    type: 'text',
                                    required: true
                                }]} />
                            <EditableDisplayField
                                label="Last Name"
                                display={user.lastName}
                                endpoint="user"
                                dataObj={user}
                                fields={[{
                                    field: 'lastName',
                                    type: 'text',
                                    required: true
                                }]} />
                            <EditableDisplayField
                                label="Phone"
                                display={user.phone}
                                endpoint="user"
                                dataObj={user}
                                fields={[{
                                    field: 'phone',
                                    type: 'text',
                                    required: true
                                }]} />
                            <EditableDisplayField
                                label="Account"
                                display={account}
                                readOnly={true} />
                            <EditableDisplayField
                                label="Role"
                                display={role}
                                readOnly={true} />
                            <ManageAuth fireauth={fireauth} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AppUserView))
