import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../assets/scss/_modal.scss'

class Modal extends React.Component {
    componentDidMount = () => {
        document.body.classList.add('modal-open')
    }

    componentWillUnmount = () => {
        document.body.classList.remove('modal-open')
    }

    handleClose = () => {
        if (this.props.closeAction) {
            this.props.closeAction()
        } else {
            let path = this.props.location.pathname
            const modalIndex = path.indexOf('/m/')
            this.props.history.push(path.substr(0, modalIndex + 1))
        }
    }

    render = () => {
        return (
            <div className="modal show d-block">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <span onClick={this.handleClose} className="close-icon">
                            <FontAwesomeIcon icon="times" title="Close Modal" />
                        </span>
                        {this.props.children}
                    </div>
                </div>
                <div className="modal-backdrop" onClick={this.handleClose} />
            </div>
        )
    }
}

export default Modal
